import $ from 'cash-dom';

export const sectionNavComponent = async () => {
  const $menu = $('#menu');
  const $menuCheck = $('#menu-check');

  if ($menu.length === 0 || $menuCheck.length === 0) {
    return;
  }

  const headerHeight = 152;
  const originalMarginTopClass = 'mt-12';

  const hasOriginalMarginTop = $menu.hasClass(originalMarginTopClass);

  const $placeholder = $(`<div class="hidden"></div>`);
  $menu.after($placeholder);

  const menuHeight = $menu.outerHeight(true);
  $placeholder.height(menuHeight);

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        $menu.addClass('fixed-menu');

        if (hasOriginalMarginTop)
          $menu.removeClass(originalMarginTopClass);

        $placeholder.removeClass('desktop:hidden');
        $placeholder.addClass('desktop:block');
      } else {
        $menu.removeClass('fixed-menu');

        if (hasOriginalMarginTop)
          $menu.addClass(originalMarginTopClass);

        $placeholder.removeClass('desktop:block');
        $placeholder.addClass('desktop:hidden');
      }
    });
  };

  const offset = hasOriginalMarginTop ? 48 : 0;

  const observer = new IntersectionObserver(observerCallback, {
    root: null,
    rootMargin: `-${headerHeight - offset}px 0px 0px 0px`,
    threshold: 0,
  });

  observer.observe($menuCheck.get(0));
};

export const sectionNavActiveComponent = async () => {
  const $navItems = $('.section-nav-item');
  const sections = [];

  if ($navItems.length === 0) {
    return;
  }

  $navItems.each(function () {
    const $navItem = $(this);
    const href = $navItem.attr('href');
    if (href && href.startsWith('#')) {
      const sectionId = href.substring(1);
      const $section = $(`#${sectionId}-container`);
      if ($section.length) {
        sections.push({
          navItem: $navItem,
          section: $section,
        });
      }
    }
  });

  const height = window.innerHeight;
  const observerOptions = {
    root: null,
    rootMargin: `-216px 0px -${height - 216}px 0px`,
    threshold: 0,
  };
  let lastObserverUpdate = null;

  const observerCallback = (entries) => {
    console.log(entries, lastObserverUpdate);
    entries.forEach(entry => {
      if (lastObserverUpdate && entry.time < lastObserverUpdate)
        return

      const isIntersecting = entry.isIntersecting/* && entry.intersectionRatio > 0*/;
      let navIndex = sections.findIndex(item => item.section.get(0) === entry.target);
      console.log(entry.target, entry.isIntersecting, isIntersecting, navIndex);

      if (isIntersecting) {
        if (navIndex > -1) {
          const navItemData = sections[navIndex];
          lastObserverUpdate = entry.time;

          sections.forEach(item => {
            item.navItem.removeClass('text-red border-b border-red').addClass('text-gray-dark');
          });
          navItemData.navItem.addClass('text-red border-b border-red').removeClass('text-gray-dark');
        }
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  sections.forEach(item => {
    observer.observe(item.section.get(0));
  });

  console.log(observer);

  $navItems.on('click', function (event) {
    const $navItem = $(this);
    const href = $navItem.attr('href');
    console.log(href);
    if (href && href.startsWith('#')) {
      const sectionId = href.substring(1);
      const $section = $(`#${sectionId}`);

      if ($section.length) {
        // $('html, body').animate({
        //   scrollTop: $section.offset().top - 152,
        // }, 500);

        sections.forEach(item => {
          item.navItem.removeClass('text-red border-b border-red').addClass('text-gray-dark');
        });
        $navItem.addClass('text-red border-b border-red').removeClass('text-gray-dark');
      }
    }
  });
};
